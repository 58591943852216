:root {
  --blue: #00B7D4;
  --black: #000;
  --white: #ffffff;
  --grey: #BCC4DF;
  --drakgrey: #282F42;
  --lightgrey: rgb(189, 196, 224);
  --darkblue: #25384A;
  --medblue: #3D4665;
  --lightblue: rgb(40, 47, 66);
  --bodycolor: rgb(28, 30, 43);
}

body {
  background-color: var(--bodycolor) !important;
  font-family: 'Poppins', sans-serif !important;
  color: #000 !important;
  overflow-x: hidden !important;

}

h1, h2, h3, h4, h5, h6, p, span, a {
  font-family: 'Poppins', sans-serif;
}

table, tr, td, th {
  /* color: var(--white); */
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}

p {
  margin-bottom: 0px !important;
}


/* =====================================Header===================================================================================================================================================================================================================================== */
.Header_Main_whole_wrapper {
  background-color: #000000;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 46px;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 9999999 !important;
  display: none;
}

.Header_logo_wrapper img {
  width: 6%;
}

.Header_Right_side_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

#menu_dropdown_select {
  display: none;
}

.Header_Right_side_content div {
  padding-right: 15px;
}

.Header_Right_side_content div p {
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  text-align: right !important;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: var(--grey);
}

.Header_Right_side_content div h5 {
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
}

.Heade_Power_button {
  background-color: var(--drakgrey);
  border-radius: 100px;
  color: var(--blue);
  font-size: 35px !important;
}

.name-log {
  margin: 0px;
  position: relative;
  background-color: var(--darkblue);
  padding: 20px;
  border-radius: 0px 0px 8px 8px;
}

.name-log_two {
  margin: 0px;
  position: fixed;
  background-color: var(--darkblue);
  padding: 20px;
  border-radius: 0px 0px 8px 8px;
  bottom: 0;
  width: 60%;
}

/* =========================================End======================================================================================================================================================================================================================================= */
/* ================================DashboardPage============================================================================================================================================================================================================================================= */

/* .Dashboard_page_Whole_content_wrapper_main {
  display: flex;
  height: 100%;
} */

.Dashboard_page_Map_wrapper_Main_whole img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.Dashboard_page_side_bar {
  width: 100%;
  background-color: var(--drakgrey);
  color: var(--grey) !important;
  text-decoration: none !important;
  border-radius: 8px !important;
}

.hidescrollbar {
  /* WebKit (Chrome, Safari) */
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: transparent transparent;
  /* For Firefox */

  /* WebKit (Chrome, Safari) */
  -webkit-scrollbar-width: thin;

  -webkit-scrollbar-track {
    background-color: transparent;
  }

  -webkit-scrollbar-thumb {
    background-color: transparent;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.Dashboard_page_side_bar_close {
  background-color: var(--drakgrey);
  color: var(--grey) !important;
  width: 0%;
  list-style: none;
  padding-left: 0px !important;
  display: none;
}

.Dashboard_page_side_bar_close a li span img {
  width: 65% !important;
}

.Dashboard_page_side_bar {
  list-style: none;
  padding-left: 0px !important;
}

.Dashboard_side_bar_links {
  text-decoration: none !important;
  color: var(--grey);
}

.Order_count_div_wrapper {
  display: flex;
  transition: height 1s ease-in-out;
  height: 0% !important;
  opacity: 0;

}

.Order_count_div_wrapper_expanded {
  height: 130px;
  transition: height 1s ease-in-out;
  opacity: 1;
}

.Orders_count_div {
  background-color: var(--drakgrey);
  margin: 10px;
  border-radius: 8px;
}

.Orders_count_div p {
  color: var(--blue);
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0px !important;
}

.Orders_count_div div {
  margin: 15px;
}

.Orders_count_p_head {
  color: var(--grey) !important;
  font-weight: bold;
  font-size: 16px !important;
}

.Orderlist_date_input button {
  color: white !important;
}

input.Date_input {
  width: 250px;
  padding: 6px;
  border-radius: 7px;
  font-size: 13px;
  position: relative;
  border: none;
  padding-left: 10px;
  background-color: var(--black);
  color: var(--grey);
}

input.Date_input:focus {
  outline: none;
  background-color: #000 !important;
}

.calendar_icon {
  position: absolute;
  top: 50%;
  right: 23px;
  transform: translateY(-50%);
  fill: #999;
  /* Set the icon color */
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.Orderlist_date_input {
  background-color: black;
  color: white;
  border: none !important;
  box-shadow: none;
  border-radius: 7px;
  padding: 4px 11px;
}

/* .Orderlist_date_input::-webkit-calendar-picker-indicator {
  background-color: var(--blue) !important;
  color:var(--blue) !important;
} */
.Orderlist_date_input:focus {
  outline: none !important;
  background-color: #000 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('/public/assets/images/Caledar_icon.svg') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.Dashboard_side_bar_links:hover {
  color: var(--white) !important;
}

.Dashboard_page_right_side {
  width: 85%;
  padding: 65px;
  margin-top: 3rem;
}

.Dashboard_SideBar_icon {
  /* filter: invert(100%) sepia(30%) saturate(4430%) hue-rotate(177deg) brightness(95%) contrast(83%) !important; */
  width: 25%;
  margin-right: 5px;
}

#dropdown-basic {
  /* Styles for the toggle button text color */
  color: var(--white);
  /* Styles for the toggle button border color */
  border: 2px solid var(--blue);
  /* Styles for the toggle button background color when not open */
  background-color: black;
  box-shadow: none;
}

/* Targeting the Dropdown menu items */
.custom-dropdown-menu .dropdown-item {
  /* Styles for the menu item text color */
  color: var(--white);
  /* Styles for the menu item background color */
  background-color: var(--drakgrey);
  border-bottom: 2px solid rgba(255, 255, 255, 0.25);
}

.dropdown-menu.show {
  background-color: var(--drakgrey);
  color: white;
}

button.accordion-button.collapsed, .accordion-button:not(.collapsed), .accordion-body {
  background-color: var(--drakgrey);
  color: white;
}

.accordion-button::after, .accordion-button:not(.collapsed)::after {
  color: white;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion-button:not(.collapsed)::after {
  filter: invert(0);
}

.custom-dropdown-menu .dropdown-item:hover {
  color: black;
  background-color: var(--blue);
}

.Dashboard_SideBar_icon_one {
  width: auto;
  height: 35px;
}

.Dashboard_page_side_bar li {
  padding: 15px 20px;
  /* padding-left: 46px; */
  cursor: pointer;
  display: block;
  align-items: center;
  justify-content: flex-start !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 15px;
  border-left: 5px solid transparent;
}

.Fleetrobotpage_popup_wrapper_two {
  display: none !important;
}

.Dashboard_page_side_bar li span {
  /* display: none; */
  width: auto !important;
}

.Dashboard_page_side_bar li span img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.Dashboard_page_side_bar_close li {
  padding: 20px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  text-align: center;
}

.Dashboard_page_side_bar_close li p {
  display: none;
}

.Dashboard_page_side_bar_close li img {
  width: 55%;
}

.col-md-10 {
  padding-right: 19px;
}

.col-md-10.collapseWidth {
  width: calc(100% - 50px);
  transition: width 0.5s ease-in-out;
  /* margin-left:5%; */
}

.col-md-2.collapseWidth {
  width: 40px;
  transition: width 0.5s ease-in-out;
}

.col-md-2, .col-md-10, .collapseWidth {
  transition: width 0.5s ease-in-out;
}

.Dashboard_side_bar_wrapper_main_long {
  width: 100%;
  /* width: 270px; */
  background-color: var(--drakgrey);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: 6px;
  top: 19px;
  border-radius: 8px !important;
  position: sticky;
  transition: width 0.5s ease-in-out;
  overflow: hidden;
}

.SideBar_collapse_div {
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
  position: absolute;
  top: 50%;
  right: -16px;
  border-radius: 7px;
  /* padding: 5px; */
  transition: 1s ease-in-out;
  opacity: 0.3;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center
}

.SideBar_collapse_div_two {
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
  position: absolute;
  top: 65px;
  right: 10px;
  border-radius: 7px 0px 0px 7px;
  /* padding: 5px; */
  transition: 1s ease-in-out;
  opacity: 0.3;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(271deg);
}

.SideBar_collapse_div_two_expanded {
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 7px 0px 0px 7px;
  /* padding: 5px; */
  /* transition: 1s ease-in-out; */
  opacity: 0.3;
  width: 30px;
  height: 30px;
  transform: rotate(271deg);
  margin-right: 11px !important;
  margin-top: -17px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999 !important;
}

.SideBar_collapse_div_two_expanded svg {
  margin-left: 6px !important;
}

.SideBar_collapse_div:hover, .SideBar_collapse_div_two:hover {
  opacity: 1;
}

.SideBar_collapse_div svg, .SideBar_collapse_div_two svg {
  font-size: 24px;
  transition: 1s ease-in-out;
}

.width_zero {
  width: 0px;
}

/* .margin_left {
  margin-left: 15%;
} */
.container {
  /* Other styles for the container */
  position: relative;
}

#Responsive_drawer>div:nth-child(3) {
  width: 60%;
  background-color: var(--drakgrey);
}

.Hamburger_icon_wrapper {
  display: none;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}


.margin_left_close {
  margin-left: 6%;
  width: 94% !important;
}

.Dashboard_side_bar_wrapper_main_short {
  width: 45px;
  background: transparent;
  /* height: 90vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* position: fixed; */
  /* top: 11%; */
  left: 0%;
  position: relative;
  transition: width 0.5s ease-in-out;
  overflow: hidden;
}

.Dashboard_side_bar_wrapper_main_short .SideBar_collapse_div {
  right: auto;
  left: -9px;
}

.SideBar_active {
  background-color: var(--bodycolor);
  color: white;
  /* font-weight: 700; */
  letter-spacing: 0px;
  /* font-size: 18px; */
  border-left: 5px solid var(--blue) !important;
}

.SideBar_active_two {
  background-color: var(--bodycolor);
  color: white;
  /* font-weight: 700; */
  letter-spacing: 0px;
  /* font-size: 18px; */
  border-bottom: 5px solid var(--blue) !important;
}

p.Dashboard_side_bar_P {
  font-weight: bold;
  color: var(--white);
}

.Dashboard_SideBar_icon_one {
  /* width:30px;
  height:30px; */
}

.Dashboard_page_rightSide_content_heading h4, .Dashboard_page_rightSide_content_heading span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 18px;
  /* color: var(--blue); */
  margin-bottom: 0px;
}

/* .Dashboard_page_rightSide_content_heading input{
  border:1px solid var(--blue);
} */
.Dashboard_page_rightSide_content_heading span {
  font-size: 20px;
  /* color:var(--white); */
  font-weight: bold;
}

span.FleetView_page_Heading_fleetName {
  color: var(--blue) !important;
}

.Dashboard_page_rightSide_content_heading {
  background-color: var(--drakgrey);
  margin: 20px 0;
  border-radius: 8px;
  padding: 8px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 64px;
  color: var(--white);
}

.Dashboard_page_rightSide_content_heading_formgroup {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.Dashboard_page_top_div_inner_div>label>span>span:nth-child(2), .Dashboard_page_rightSide_content_heading_formgroup>label>span>span:nth-child(2) {
  background-color: var(--blue) !important;
  opacity: 1 !important;
}

.Dashboard_page_top_div_inner_div span {
  font-size: 20px;
  color: var(--white);
  font-weight: bold;
  border: none !important;
}

/* .Dashboard_page_rightSide_content_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:20px;
  position: fixed;
  top: 10px;
  background-color: var(--drakgrey);
  width: 80.3%;
  padding: 18.5px;
  z-index: 9999999999 !important;
  border-radius:5px;
  margin-left:37px
} */
.PaginationWrapper {
  /* margin-top: 20px; */
}

/* .PaginationWrapper .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
background-color: var(--blue);
color: var(--white) !important;

} */
.PaginationWrapper ul li button {
  color: var(--blue);
  border: 1px solid var(--blue) !important;
  border-radius: 7px;
  font-weight: bold;
}

.PaginationWrapper ul {
  justify-content: flex-end;
}

.countSize_p {
  font-weight: bold;
  color: var(--blue);
}

.Dashboard_page_rightSide_content_heading_span {
  padding-right: 20px;
  padding-top: 0px;
}

.switchcase-active {
  color: var(--grey) !important;
  font-weight: 300 !important;
}

.menu-bar-btn {
  background-color: transparent;
  color: var(--white);
  border: none;
  text-align: center;
  margin: 20px;
}

.Dasboard_page_individual_fleet_box_wrapper {
  background-color: var(--drakgrey);
  color: var(--white);
  padding: 23px;
  border-radius: 8px;
  /* margin: 23px;
  width:310px; */
  cursor: pointer;
  /* margin-left:0px;
  margin-top:0px; */
}

.Dasboard_page_individual_robot_box_wrapper {
  background-color: var(--drakgrey);
  color: var(--white);
  /* padding: 23px; */
  border-radius: 8px;
  margin-top: 20px;
  /* margin: 23px; */
  /* width:33.333%; */
  cursor: pointer;
  /* margin-left:0px; */
}

.Dasboard_page_individual_robot_box_wrapper:hover .Battery_swap_span {
  visibility: visible !important;
}

.Dasboard_page_individual_robot_box_1 {
  background-color: var(--darkblue);
  border-radius: 8px;
  padding: 14px;
}

.Dasboard_page_individual_robot_box_2 {
  padding: 14px;
}

.Dashboard_page_fleets_list_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.Dashboard_page_fleets_list_wrapper_two {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.Dasboard_page_individual_fleet_box_inner_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

div#map-container-id {
  border-radius: 8px;
  width: 100% !important;
}

div#map-container-id canvas {
  width: 100% !important;
  height: auto !important;
}

.Dasboard_page_individual_fleet_box_wrapper h4 {
  text-align: center;
  font-size: 17px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 15px;
}

.SelectFleetPage-inner-li-div-robot-id span {
  display: block;
  opacity: 0.7;
}

.robotId_p {
  white-space: nowrap;
}

.Dashboard_page_individual_fleet_Heading_Green_h4::after {
  content: '';
  background-color: #88F976;
  width: 20%;
  height: 3px;
  position: absolute;
  left: 40%;
  top: 31px;
}

.Dashboard_page_individual_fleet_Heading_red_h4::after {
  content: '';
  background-color: #FF5757;
  width: 20%;
  height: 3px;
  position: absolute;
  left: 40%;
  top: 31px;
}

.Dasboard_page_individual_fleet_box_inner_div p {
  color: var(--grey);
}

.Map-Main-Whole-wrapper {
  text-align: center;
}

.FleetPage_map_tab_heading_wrapper {
  padding: 23px 31px;
}

.FleetPage_map_tab_heading_wrapper h3 {
  font-size: 19px;
}

.Dasboard_page_individual_fleet_box_inner_div p:last-child {
  color: var(--grey);
  font-weight: 600;
}

.Dashboard_page_robotStatusClass {
  font-size: 12px !important;
  opacity: 1 !important;
  text-transform: uppercase !important;
}

.Dasboard_page_individual_robot_h4 {
  font-size: 15px;
  margin-bottom: 2px;
}

.Dashboard_page_reddot_wrapper {
  position: relative;
  padding-top: 7px;
  padding-right: 12px
}

.owl-theme .owl-dots {
  display: none !important;
}

.Dashboard_page_fleets_list_wrapper .owl-nav.disabled {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  top: 20%;
  position: absolute;
}

.Dashboard_page_fleets_list_wrapper button.owl-prev, .Dashboard_page_fleets_list_wrapper_two button.owl-prev {
  margin-left: -2rem !important;
  z-index: 999999 !important;
}

.Dashboard_page_fleets_list_wrapper button.owl-next, .Dashboard_page_fleets_list_wrapper_two button.owl-next {
  margin-right: -2rem !important;
}

button.owl-prev:hover {
  background: transparent !important;
}

button.owl-next:hover {
  background: transparent !important;
}

.Dashboard_page_fleets_list_wrapper_two .owl-nav.disabled {
  width: 100%;
  display: flex !important;
  justify-content: space-between;
  top: -4%;
  position: absolute;
}

.Dashboard_page_fleets_list_wrapper span, .Dashboard_page_fleets_list_wrapper_two span {
  font-size: 100px;
  color: #969393;
  font-weight: 200;
}

.Dashboard_page_fleets_list_wrapper span:hover, .Dashboard_page_fleets_list_wrapper_two span:hover {
  background-color: transparent;
}

.red-dot {
  background-color: #FF6767;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.green-dot {
  background-color: #88F976;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.d-flex-center.Dasboard_page_individual_robot_box_2 {
  position: relative !important;
}

.yellow-dot {
  background-color: #FFB800;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes dotanimation {
  0% {
    opacity: 0
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.Dashboard_page_robot_div_icons {
  display: flex;
  justify-content: space-around;
  /* margin-top: 15px; */
  font-size: 14px;
  align-items: center;
}

.Dashboard_page_robot_div_icons img {
  margin-right: 5px;
  margin-left: 5px;
}

.Dashboard_page_individual_robot_robotstatus_div {
  text-align: center;
  /* margin-top: 11px; */
  font-size: 13px;
}

.Dashboard_page_Robot_Card_heading {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 18px;
  color: var(--blue);
  margin-bottom: 0;
}

.Dashboard_page_robot_card_heading_wrapper {
  background-color: var(--drakgrey);
  padding: 15px 30px;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  min-height: 64px;
  /* color: var(--blue); */
}

.Dashboard_page_Robot_Card_heading_two {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: var(--white);
  font-size: 26px;
  margin: 25px;
}

.Dashboard_page_Robot_Card_heading span {
  font-size: 13px;
}

.owl-carousel .owl-item img {
  /* width: 10% !important; */
}

.tools {
  text-align: flex-end;
  position: absolute;
  z-index: 999999;
  right: 20px;
}

.tools button {
  background: var(--medblue) !important;
  font-weight: 600;
  margin: 5px;
  color: var(--grey);
  border: none;
  padding: 2px 12px;
  border-radius: 8px;
}

.Dashboard-content-div-one-left-side-h3 {
  color: var(--white);
  text-align: left !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 25px;
  margin-bottom: 0px;
}

/* ======================================End========================================================================================================================================================================================================================================================== */
/* =====================FleetPage========================================================================================================================================================================================================================================================== */
.Fleet_page_Whole_content_wrapper_main {
  display: flex;
  height: auto;
  /* margin-top: -20px; */
  /* margin: 30px; */
  /* margin-right: 57px; */
  /* margin-top: 6px; */
}

.Dashboard_page_right_side_fleetpage {
  color: var(--white);
  width: calc(100% - 270px);
  /* margin-top: 90px; */
  padding: 60px;
  /* padding-right: 0px; */
  /* padding-top: 15px; */
}

.Dashboard_page_right_side_fleetpage h4 {
  padding-left: 5px;
  font-size: 15.9px;
  padding-bottom: 13px;
  padding-top: 9px;
}

.Fleet_page_table_Main_whole {
  width: 100%;
}

.Fleet_page_table_Main_whole thead {
  /* background-color: rgb(40,47,66); */
  border-bottom: 2px solid var(--blue);
}

.Fleet_page_table_Main_whole th {
  color: var(--grey);
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  white-space: nowrap;
}

.Fleet_page_table_Main_whole td, .Fleet_page_table_Main_whole th {
  padding: 8px 18px;
  /* padding-left: 29px; */
  /* padding-right: 25px; */
}

.Fleet_page_table_Main_whole tbody tr {
  border-bottom: 1px solid #BCC4DF24;
}

.Fleet_page_table_Main_whole td {
  /* font-weight: 400; */
  font-size: 12px;
  line-height: 15px;
  padding: 6px 18px;
  color: var(--white);
  /* border-bottom: 1px solid #BCC4DF24; */
}

.SideBar_name_p {
  /* width:150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
  color: var(--white);
  font-size: 13px;
}

.FleetPage_table_action_td {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 0px !important;
}

.FleetPage_table_action_td img {
  /* margin-right:10px;
margin-left:10px; */
  margin: 13px;
}

.FleetPage_table_status_td_img {
  width: 20px;
  height: 20px;
}

.FleetPage_plusIcon {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  color: #000 !important;
  background-color: var(--grey);
  border-radius: 100%;
  padding: 13px;
}

.css-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
  background-color: var(--grey) !important;
}

.css-ohncnb-MuiSpeedDialIcon-root .MuiSpeedDialIcon-icon {
  color: var(--black);
}

.SelectPage-robotList-wrapper {
  display: grid;
  flex-wrap: wrap;
  align-items: center;
  padding: 11px;
  justify-content: flex-start;
  margin: 35px;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.SelectFleetPage-inner-li-div {
  /* width: 90%; */
  /* margin: 7px; */
  background: var(--lightblue);
  cursor: pointer;
  border-top: none !important;
  cursor: pointer;
  position: relative !important;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-left-side-div-one-inner-div {
  text-align: flex-end;
  margin-top: -8px;
  justify-content: space-between;
  padding-left: 11px;
  padding-right: 0px;
}

.battery-icon-css_three {
  width: 35px;
  position: relative;
  margin-right: 10px;
  color: var(--white);
  display: flex;
}

.battery-icon-css_three_collapse {
  width: 35px;
  position: relative;
  /* margin-right: 10px; */
  color: var(--white);
  display: flex;
}

.Battery_inner_wrapper {
  width: 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px;
  height: 11px;
  display: inline-block;
}

.battery-icon-css_three_collapse .Battery_inner_wrapper {
  width: 22px;
}

.battery-icon-css_three:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: var(--white);
  position: absolute;
  top: 5px;
  right: -4px;
}

.battery-icon-css_three_collapse:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: var(--white);
  position: absolute;
  top: 3px;
  right: 11px;
}

.collapse_side_bar_class {
  max-width: 12.5% !important;
}

.collapse_side_bar_class_width {
  max-width: 100% !important;
}

/* ==========================End===================================================================================================================================================================================================================================================== */
/* =============================Individual_fleet_page============================================================================================================================================================================================================================================================= */

.Individual_fleet_page_locations_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 25px; */
  margin-top: 20px;
}

.Individual_fleet_page_location_header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);

  /* border-bottom: 1px solid #5f5c5c; */
  /* padding-bottom: 13px; */
}

.FleetPage_map_toggle_wrapper>label>span>span>span {
  color: var(--white) !important;
}

.FleetPage_map_toggle_wrapper>label>span>span:nth-child(2) {
  background-color: var(--blue) !important;
}

.Individual_fleet_page_location_header_wrapper h4 {
  font-size: 18px;
}

.Individual_fleet_page_pickup_location_wrapper, .Individual_fleet_page_dropoff_location_wrapper {
  /* background-color:rgb(40,47,66); */
  /* padding: 14px; */
  /* height:auto; */
  /* overflow-y:scroll; */
  /* height:310px; */
  /* margin:10px; */
  /* width:590px; */
  /* border-radius:7px; */
  background-color: var(--lightblue);
  border-radius: 7px;
  padding: 15px;
}

.Individual_fleet_page_Home_location_first_div {
  background-color: var(--lightblue);
  padding: 14px;
  height: auto;
  /* overflow-y: scroll; */
  /* height: 310px; */
  margin: 32px;
  width: 95%;
  border-radius: 5px;
  margin-bottom: 0px;
  border-bottom: 1px solid #5f5c5c;
}

.Individual_fleet_page_pickup_location_wrapper::-webkit-scrollbar, .Individual_fleet_page_dropoff_location_wrapper::-webkit-scrollbar {
  background-color: var(--drakgrey);
  color: var(--white) !important;
  width: 10px;
}

.Individual_fleet_page_pickup_location_wrapper::-webkit-scrollbar-thumb, .Individual_fleet_page_dropoff_location_wrapper::-webkit-scrollbar-thumb {
  background-color: #7d7d7d !important;
}

.Individual_fleet_page_location_header_wrapper h4 {
  margin-bottom: 0px;
  padding-left: 0px;
}

.Individual_fleet_page_location_header_wrapper button {
  color: var(--black) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: var(--lightgrey) !important;
  padding: 5px 27px !important;
  /* border-radius: 50px!important; */
  border: none;
  font-weight: 700;
  border-radius: 5px;
}

.Individual_fleet_page_location_content_table {
  width: 100%;
  background: var(--lightblue);
  /* box-shadow: 1px 1px 3px 1px #000; */
  margin-top: 17px;
  /* border: 1px solid white; */
  height: 100%;
  border-radius: 5px;
}

.Individual_fleet_page_location_content_table tr {
  border-bottom: 1px solid #5f5f5f !important;
}

.Individual_fleet_page_location_content_table tr th {
  padding: 10px;
  color: var(--grey);
  font-weight: 500;
  /* padding-left:21px; */
}

.Individual_fleet_page_location_content_table tr td {
  padding: 10px;
  /* padding-left:21px; */
}

.Individual_fleetPage_pickupLocation_modal_h3 {
  color: var(--white);
  font-size: 23px;
  font-weight: 700;
}

.Individual_fleet_page_Home_location_first_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 21px;
}

.Individual_fleet_page_Home_location_first_div h2 {
  font-size: 16px;
}

.Individual_fleet_page_Home_location_first_div button {
  color: var(--black) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: var(--lightgrey) !important;
  padding: 5px 27px !important;
  border: none;
  font-weight: 700;
  border-radius: 5px;
}

.Order_modal_h3 {
  color: var(--blue);
  font-size: 24px;
  font-weight: bold;
}

.AssignRobot_button {
  width: 300px;
  background-color: transparent;
  border: none;
  color: #E96C67;
  font-size: 14px;
  border-radius: 50px;
  padding: 3px 27px;
  text-decoration: underline;
}

.addlocation_textfield input {
  padding: 12px;
}

.addlocation_toggle {
  padding-left: 20px;
}

.addlocation_toggle p {
  color: white;
  font-weight: 500;
  margin-bottom: 5px;
}

.AddLocation_modal_button_wrapper {
  text-align: flex-end;
  margin: 15px;
}

.AddLocation_modal_save_button {
  color: var(--black) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: var(--lightgrey) !important;
  padding: 3px 27px !important;
  /* border-radius: 50px!important; */
  border: none;
  font-weight: 700;
  margin: 10px;
  border-radius: 50px;
}

.Order_button_wrapper {
  text-align: flex-end;
  margin: 15px;
}

.Order_save_button {
  color: var(--white) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: var(--blue) !important;
  padding: 10px 20px !important;
  /* border-radius: 50px!important; */
  border: none;
  font-weight: bold;
  margin: 20px;
  border-radius: 8px;
}

.OrderModal_heading_wrapper {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.Order_modal_cancel_button {
  color: var(--white) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: transparent !important;
  font-weight: bold;
  /* margin: 10px; */
  box-shadow: none;
  border: none;
}

.Order_modal_button_wrapper {
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  margin-top: 35px;
}

.AddLocation_modal_cancel_button {
  color: var(--black) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: rgb(158 158 158) !important;
  padding: 3px 27px !important;
  /* border-radius: 50px!important; */
  border: none;
  font-weight: 700;
  margin: 10px;
  border-radius: 50px;
}

.Individual_FleetPage_Statustd_wrapper label {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.FleetPage_top_bar_wrapper {
  background-color: var(--drakgrey);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 44px;
  position: fixed;
  width: 80%;
  top: 10px;
  z-index: 9999999999 !important;
  right: 30px;
  border-radius: 5px;
}

.FleetPage_top_bar_left_side {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
}

.FleetPage_top_bar_left_side div {
  margin: 0px 25px;
  font-size: 15px;
  /* font-weight: bold; */
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  text-wrap: nowrap;
}

.top_bar_active {
  /* border-bottom:3px solid #BCC4DF; */
  cursor: pointer;
  color: var(--white);
  font-weight: bold;
  background-color: #1C1E2C;
  border: 3px solid var(--blue);
}

.Fleetpage_map_tab_wrapper {
  padding-top: 5px;
  padding-bottom: 44px;
}

.Fleet_page_Map_list_wrapper {
  /* margin: 30px; */
  margin-top: -20px;
}

.Fleet_page_Map_list_img {
  width: 200px;
  height: 150px;
}

.Fleet_page_Map_list_div_one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
}

.Fleet_page_Map_list_individual_map_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 3px solid var(--drakgrey); */
}

.Fleet_page_Map_list_p {
  font-weight: 200;
}

.Fleet_page_Map_list_p span {
  font-weight: 600;
}

/* =====================================End================================================================================================================================================================================================================================================================= */
/* ====================Forgot Password Page=========================================================================================================================================================================================================================================== */
.Cart_page_otp_input input {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid var(--grey);
  background-color: var(--bodycolor) !important;
  color: var(--grey) !important;
}

.Cart_page_otp_input {
  justify-content: center !important;
}

.Loginpage-main-form-inner-div_otp {
  margin: auto;
  width: 100%;
  border-radius: 8px;
  margin-top: 15px;
}

/* =============================End======================================================================================================================================================================================================================= */
/* =============================LoginPage======================================================================================================================================================================================================================= */
.Login-page-form {
  width: 500px;
  /* margin: auto; */
  background: var(--lightblue);
  /* box-shadow:0px 4px 33px 12px rgb(40,47,66); */
  padding: 50px;
  /* padding-top: 37px; */
  border-radius: 8px;
  text-align: center;
  margin-top: 2rem;
}

.Login_page-wholeMain-wrapper {
  /* background-image: url('../public/assets/images/Ottonomy_Logo_With\ TM_Version\ 2-02\ 3.png') ,url('../public/assets/images/Ottonomy_Logo_With TM_Version 2-02 2two.png') ;
  background-repeat: no-repeat ,no-repeat;
  background-position: top left , bottom right; */
}

/* .Login_page-wholeMain-wrapper:before{
  content:'';
  background-image: url('../public/assets/images/Ottonomy_Logo_With\ TM_Version\ 2-02\ 3.png');
  background-repeat: no-repeat;
  background-size: 200px;
  height: 200px;
  left: 0;
  opacity: 1;
  position: absolute;
  width: 200px;
  top: 20px;
} */
/* .Login_page-wholeMain-wrapper:after{
  background-image: url('../public/assets/images/Ottonomy_Logo_With TM_Version 2-02 2two.png');
  background-repeat: no-repeat;
  background-size: 200px;
  content: "";
  height: 200px;
  right: 0;
  opacity: 1;
  position: absolute;
  width: 200px;
  bottom: 20px;
} */
input:focus {
  background-color: transparent;
}

/* input:-webkit-autofill{
  background-color: transparent !important;
  color:transparent !important;
} */
.LoginPage-Main-wrapper {
  height: 100vh;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  /* background-color: white; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root {
  width: 95%;
  margin: auto;
}

.Login-page-form-logo-image {
  width: auto;
  height: 50px
}

.Loginpage-main-form-inner-div {
  background-color: var(--bodycolor) !important;
  margin: auto;
  width: 100%;
  border-radius: 8px;
}

div#demo-simple-select {
  /* border: none !important; */
  border-bottom: 0.5px solid var(--grey) !important;
  padding: 0px;
  color: var(--grey);
}

div#demo-simple-select_orderList {
  /* border: none !important; */
  border-bottom: 0.5px solid var(--grey) !important;
  padding: 7px;
  /* padding-left:0px; */
  color: rgba(189, 196, 224, 0.5) !important;
  border-radius: 0px !important;
}

div#demo-simple-select_two {
  border-bottom: 0.5px solid var(--grey) !important;
  color: var(--lightgrey) !important;
}

.INdividual_fleetPage_assignrobot_select label {
  color: var(--white) !important;
}

.INdividual_fleetPage_assignrobot_select svg {
  color: var(--grey);
}

.INdividual_fleetPage_assignrobot_select fieldset {
  border-color: var(--white) !important;
}

.AddFleet_form_field_wrapper>div>div svg {
  color: rgb(151, 159, 184) !important;
}

.form__control__wrapper {
  display: flex;
  gap: 10px;
}

.AddFleet_form_field_wrapper fieldset {
  border: none !important;
}

.Loginpage-main-form-inner-div>div>div::before {
  border-bottom: none !important;

}

.No_orderstoshow_div {
  text-align: center;
}

.No_orderstoshow_div p {
  color: var(--white);
}

.Loginpage-main-form-inner-div>div>div::after {
  border-bottom: none !important;
}

.Login_page_input_wholeLabel_wrapper {
  margin-bottom: 1rem;
}

.Login_page_input_wholeLabel_wrapper label {
  font-family: 'Poppins', sans-serif;
}

.Login-form-p {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: rgb(1, 115, 212);
  font-weight: 600;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.Login-page-form h6 {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-size: 17px;
  margin-top: 0px !important;
}

.Loginpage\=-main-form {
  /* margin: 24px; */
  margin-right: 0px;
  margin-left: 0px;
  /* margin-bottom: 13px; */
  /* margin-top: 46px; */
}

.Loginpage\=-main-form input {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: var(--lightgrey) !important;
  letter-spacing: 0px !important;
  padding: 12px;
  background: none !important;
  font-family: 'Poppins', sans-serif;
}

.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15.9px;
  font-family: 'Poppins', sans-serif;
  color: var(--lightgrey);
  font-weight: 500;
}

.Loginpage-btn-wrapper-btn {
  color: var(--white) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: var(--blue) !important;
  padding: 13px 70px !important;
  border-radius: 8px !important;
  /* width: 21% !important; */
  border: none;
  font-weight: 700;

}

.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error {
  font-weight: 400;
  font-size: 10px;
  padding-left: 39px;
}

.Login-page-form .css-dmmspl-MuiFormGroup-root {
  padding-left: 15px
}

img.Logout-image {
  width: 100%;
  margin-left: 2px;
}

.Login-page-remember-checkbox {
  width: 25px;
  height: 25px;
}

.Login-page-remember-checkbox-span {
  margin: 10px;
  color: var(--lightgrey);
}

.Loginpage\=-main-form input:-webkit-autofill {
  background: none !important;
}

.Loginpage\=-main-form input:-webkit-autofill,
.Loginpage\=-main-form input:-webkit-autofill:hover,
.Loginpage\=-main-form input:-webkit-autofill:focus,
.Loginpage\=-main-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--bodycolor) inset !important;
  -webkit-text-fill-color: var(--white) !important;
  /* border-radius: 8px !important; */
  /* border-radius: 8px !important; */
  caret-color: white;
}

.AddFleet_form_field_wrapper input:-webkit-autofill {
  background: none !important;
}

.AddFleet_form_field_wrapper input:-webkit-autofill, .AddFleet_form_field_wrapper input,
.AddFleet_form_field_wrapper input:-webkit-autofill:hover,
.AddFleet_form_field_wrapper input:-webkit-autofill:focus,
.AddFleet_form_field_wrapper input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--bodycolor) inset !important;
  -webkit-text-fill-color: var(--lightgrey) !important;
}

.AddFleet_form_field_wrapper input {
  padding-left: 10px;
  padding: 10px;
}

.Loginpage-btn-wrapper {
  margin-top: 2rem;
}

.Login_page_forgot_password_div_wrapper p {
  text-align: flex-end;
  color: var(--lightgrey);
  width: 100%;
  font-size: 13px;
  margin-top: 0px;
}

/* =================================End======================================================================================================================================================================================================================================================================= */

/* =====================================IndividualFleet=================================================================================================================================================================================================================================================================== */
.FleetView-Page-Top-Content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 70px;
}

.FleetView_page_Play_Pause_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10%;
}

.FleetView_page_Play_Pause_wrapper img {
  width: 60% !important;
}

.FleetView_page_Heading_fleetName {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  font-feature-settings: 'tnum'on, 'lnum'on;
  color: var(--white);
}

.FleetView_page_haeding_tag {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 17px;
  font-feature-settings: 'tnum'on, 'lnum'on;
  color: var(--white);
}

.FleetView_page_Play_Pause_wrapper p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11.7px;
  line-height: 12px;
  font-feature-settings: 'tnum'on, 'lnum'on;
  margin-left: 5px;
  color: var(--white);
  margin-top: 4px;
}

.FleetView_page_OrderList_content {
  margin-right: 20px;
}

.FleetView_page_OrderList_pause_wrapper {
  text-align: right;
  display: flex;
  justify-content: right;
  align-items: center;
}

.FleetView_page_Image_Button_wrapper_wholeMain {
  position: relative;
}

.FleetView_page_Popper_content_one_wrapper {
  padding: 30px;
}

.FleetView_page_Popper_content_one_wrapper_inner-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.FleetView_page_Popper_content_one_wrapper_inner_two {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.FleetView_page_Popper_content_two {
  margin: 23px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  font-size: 11.9px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: rgb(50, 234, 9) !important;
  font-size: 11.9px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: rgb(166, 241, 149) !important;
  font-size: 11.9px !important;
}

.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-size: 11.9px;
}

.css-zpcwqm-MuiStepConnector-root {
  top: 7px !important;
}

.FleetView_page_Popper_content_two_wrapper {
  padding: 30px;
  padding-left: 24px;
  padding-right: 24px;
}

.Individual_fleetPage_map_toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 15px;
}

.robotpage_toggle_wrapper {
  padding: 29px 39px 29px 13px;
}

.FleetView_page_Popper_content_one_wrapper_inner-one h6, .FleetView_page_Popper_content_one_wrapper_inner-one p {
  margin-bottom: 0px !important;
}

.FleetView_page_Popper_content_one_wrapper_inner-one div {
  margin: 15px;
}

.Individual_fleet_page_map_wrapper {
  /* margin: 2rem; */
  /* margin-top:-10px; */
  /* height: 100%; */
  position: relative;
}

.FleetPage_addMap_button_wrapper {
  text-align: flex-end;
  padding-bottom: 27px;
  padding-right: 26px;
}

.FleetPage_add_map_button {
  color: var(--black) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: var(--lightgrey) !important;
  padding: 8px 24px !important;
  /* border-radius: 50px!important; */
  border: none;
  font-weight: 700
}

.FleetView_page_OrderList_content button {
  color: var(--black) !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px !important;
  background-color: var(--lightgrey) !important;
  padding: 10px 50px !important;
  border-radius: 50px !important;
  /* width: 21% !important; */
  border: none;
  font-weight: 700;
}

/* =====SecondSub=============== */

.navContainer {
  display: flex;
  justify-content: space-between;
  background-color: #dfdfdf;
  margin-bottom: 40px;
}

.uppr {
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-size: 13px;
  line-height: 15px;
  background: #D5D2D2;
  padding: 10px;
  box-shadow: none;
}

.uppr h3 {
  font-weight: 400;
  margin-bottom: 0px;
  border-right: 1px solid var(--black);
  padding: 5px;
  font-size: 13px !important;
  line-height: 15px !important;
  padding-left: 13px;
}

.FleetView_page_Image_Button_wrapper_wholeMain {
  position: relative;
  height: inherit;
}

.btn-grp {
  right: 0;
  /* display: flex; */
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 999999;
  top: 6rem;
  right: 6rem;
}

.btn-top {
  border-radius: 50px !important;
  background-color: var(--grey) !important;
  border: #ccc !important;
  padding: 8px 6px !important;
  align-self: center !important;
  margin-bottom: 16px !important;
  width: 165px !important;
  color: var(--black) !important;
  text-transform: capitalize !important;
}

.fl-map {
  width: 95%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: -6rem;
}

.t-des {
  float: left;
  background: #292F43;
  padding: 4px 8px;
  display: flex;
  text-align: center;
  margin: 30px 80px;
  border-radius: 20px;
  border-left: 8px solid #000;
  /* position: absolute;
  bottom: 40px; */
  z-index: 9999999999;
  /* box-shadow: 0 2px 5px #ccc; */
  /* left:22px; */
  width: auto;
}

#full-width {}

.map-icons {
  position: fixed;
  display: none;
  flex-direction: column;
  padding-bottom: 2px;
  right: 80px;
  bottom: 30px;
  z-index: 2;
}

.uppr h3 span {
  font-weight: 600;
}

h3 span {
  margin-left: 14px;
  padding-right: 20px;
}

.dashboard-left-side-div-one-wrapper h3 span {
  margin-left: 0px;
  padding-right: 0px;
}

table td .red-alert {
  color: #FF6767;
}

table td .orange-alert {
  color: #FFB800;
}

table td .yellow-alert {
  color: #FFDD2D;
}

table td .green-alert {
  color: #88F976;
}

.fill-square {
  border-radius: 6px;
  padding: 4px;
  color: rgba(0, 0, 0, 0.567);
  background-color: var(--grey);
  margin-bottom: 14px;
}

.fill-circle {
  border-radius: 12px;
  padding: 4px;
  background-color: var(--grey);
  color: rgba(0, 0, 0, 0.566);
  margin-bottom: 14px;
}

table td span {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 25px;
  padding: 0 0px;
  white-space: nowrap;
}

.table_wrapper {
  overflow-x: auto;
}

.marker-1 {
  background-image: url('../public/assets/images/robot-icon-1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-1 1s ease-in-out infinite;
}

.marker-2 {
  background-image: url('../public/assets/images/robot-icon-2.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-2 1s ease-in-out infinite;
}

.marker-3 {
  background-image: url('../public/assets/images/robot-icon-3.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-3 1s ease-in-out infinite;
}

.marker-4 {
  background-image: url('../public/assets/images/robot-icon-4.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-4 1s ease-in-out infinite;
}

.marker-5 {
  background-image: url('../public/assets/images/robot-icon-5.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-5 1s ease-in-out infinite;
}

.marker-6 {
  background-image: url('../public/assets/images/robot-icon-6.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-6 1s ease-in-out infinite;
}

.marker-7 {
  background-image: url('../public/assets/images/robot-icon-7.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-7 1s ease-in-out infinite;
}
.destinationMarker-1 {
  background-image: url('../public/assets/images/robot-icon-dropoff_1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-1 1s ease-in-out infinite;
}

.destinationMarker-2 {
  background-image: url('../public/assets/images/robot-icon-dropoff_1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-2 1s ease-in-out infinite;
}

.destinationMarker-3 {
  background-image: url('../public/assets/images/robot-icon-dropoff_1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-3 1s ease-in-out infinite;
}

.destinationMarker-4 {
  background-image: url('../public/assets/images/robot-icon-dropoff_1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-4 1s ease-in-out infinite;
}

.destinationMarker-5 {
  background-image: url('../public/assets/images/robot-icon-dropoff_1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-5 1s ease-in-out infinite;
}

.destinationMarker-6 {
  background-image: url('../public/assets/images/robot-icon-dropoff_1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-6 1s ease-in-out infinite;
}

.destinationMarker-7 {
  background-image: url('../public/assets/images/robot-icon-dropoff_1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example-7 1s ease-in-out infinite;
}

.fleet_marker {
  background-image: url('../public/assets/images/robot-icon-dropoff_1.svg');
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  animation: example 1s ease-in-out infinite;
}

/* ============================================End====================================================================================================================================================================================================================================================================== */

/* ============================================Popup Card====================================================================================================================================================================================================================================================================== */
.popupcard-box {
  width: 270px;
  float: left;
  background: rgb(255, 255, 255);
  padding: 32px;
  border-radius: 28px !important;
  text-align: center;
  margin: 0 15px;
  padding-top: 24px;
}

.popupcard-box h2 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  /* // margin-bottom: 20px; */
}

.popupcard-box h2:before {
  position: absolute;
  width: 26px;
  content: ' ';
  height: 2px;
  background: #FAA659;
  bottom: -8px;
  left: 45%;
  border-radius: 4px;
}

.popupcard-box h2.orange-alert:before {
  background: var(--orange-alert);
}

.popupcard-box h2.yellow-alert:before {
  background: var(--yellow-alert);
}

.popupcard-box h2.green-alert:before {
  background: var(--green-alert);
}

.popupcard-box p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  font-feature-settings: 'tnum'on, 'lnum'on;
  color: #444343;
}

.popupcard-box p span {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  font-feature-settings: 'tnum'on, 'lnum'on;
  color: #444343;
}

/* ============================================End====================================================================================================================================================================================================================================================================== */
/* ======================================Map========================================================================================================================================================================================================================================================================================= */
.Dashboard_page_Map_wrapper_Main_whole h4 {
  color: var(--white);
}

/* =========================================End=================================================================================================================================================================================================================================================================================================== */

/* ================Dashboard Bottom Bar=========================================================================================================================================================================================================================================================================================================== */
.Dashboard_bottom_bar_whole_wrapper {
  display: none;
}

ul.Dashboard_bottomBar_ul_wrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  background-color: var(--drakgrey);
  margin-bottom: 0;
  list-style: none;
  z-index: 9999999 !important;
  align-items: center;
  padding-left: 0px;
  border-top: 3px solid #FFFFFF10;
}

ul.Dashboard_bottomBar_ul_wrapper li {
  text-align: center;
  padding: 10px;
}

/* ========================End============================================================================================================================================================================================================================================================================================================== */

/* ====================RobotIndividual=================================================================================================================================================================================================================================================================================================================== */
.dashboard-left-side-div-wrapper-Main {
  padding: 30px;
}

.dashboard-left-side-div-one-wrapper h3 {
  color: var(--white);
  font-size: 17px;
  margin-bottom: 0px;
  text-align: flex-start;
}

.dashboard-left-side-div-one-inner-div {
  text-align: flex-end;
  /* margin-top: 15px; */
  padding-right: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 11px;
  padding-right: 0px;
}

.dashboard-left-side-div-one-wrapper span {
  color: rgb(113, 207, 98);
  font-size: 13px;
  text-transform: uppercase !important;
}

.dashboard-left-side-div-one-wrapper-battery-div p {
  margin-bottom: 0px;
  color: var(--white);
  font-size: 8px;
  margin: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.orderStatus_p {
  font-weight: bold;
}

.dashboard_left_side_available_div {
  text-align: left;
}

.dashboard_left_side_available_div h4 {
  font-size: 10px;
  color: var(--white) !important;
}

.RobotActions_content_wrapper_div_orange {}

.dashboard-left-side-div-one-wrapper-battery-div {
  display: inline-block;
  text-align: center;
}

.dashboard-left-side-div-two-wrapper-h5 {
  color: var(--white);
  font-weight: 300;
  font-size: 15px;
}

.dashboard-left-side-div-three-wrapper {
  margin-top: 16px;
}

.dashboard-left-side-div-four-wrapper {
  margin-top: 16px;
}

.dashboard-left-side-div-two-Map-Container-one {
  background-color: var(--drakgrey);
  height: 170px;
  overflow: scroll;
}

.dashboard-left-side-div-two-Map-Container-two {
  background-color: var(--drakgrey);
  /* height: 170px; */
  /* border:3px solid rgb(251,98,98) !important; */
  border-radius: 8px;
}

.dashboard-left-side-div-two-Map-Container-two h4 {
  color: var(--white);
  font-size: 17px;
  text-align: center;
  padding: 19px 13px;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar, .Dashboard_page_right_side_fleetpage_orderlist::-webkit-scrollbar {
  background-color: var(--drakgrey);
  color: var(--white) !important;
  width: 10px;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-track, .Dashboard_page_right_side_fleetpage_orderlist::-webkit-scrollbar-track {
  color: var(--white) !important;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-thumb, .Dashboard_page_right_side_fleetpage_orderlist::-webkit-scrollbar-thumb {
  background-color: #7d7d7d !important;

}

.dashboard-left-side-div-two-robot-button-Container.d-flex-center {
  background-color: var(--drakgrey);
  flex-wrap: wrap !important;
}

.dashboard-left-side-div-two-robot-button-Container button {
  border-radius: 5px;
  padding: 5px 34px;
  background-color: var(--lightgrey);
  border: none !important;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 17px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: auto;
}

.dashboard-right-side-div-wrapper-Main {
  background-color: var(--drakgrey);
  height: auto;
}

.Video_stream_iframe {
  position: absolute;
  width: 400px;
  height: 200px;
  right: 0px;
  bottom: 0px;
  z-index: 99999999 !important;
}

.Dashboard-Page-bottom-div-Main-wrapper {
  width: 100%;
  background-color: var(--black);
  position: relative;
  top: 0;
  padding: 10px 25px;
  /* z-index:999999 !important; */
}

.orderlist_table td {
  white-space: nowrap;
}

.DashBoard-page-Main-Whole-wrapper {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
}

.Dashboard_page_bottom_div_inner_one_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
}

.Dashboard_page_bottom_div_inner_two_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}

.Dashboard_page_bottom_div_inner_two_div button {
  margin-right: 10px;
  margin-left: 10px;
}

.Dashboard_page_bottom_div_inner_two_div_inner_one {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Dashboard_page_bottom_div_inner_two_div_inner_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_page_Order_Id_Wrapper {
  display: flex;
  justify-content: space-between;
  /* margin-bottom:5px; */
  margin-right: 22px;
  width: 100%;
}

.dashboard_page_Order_Id_Wrapper_inner_div {
  display: flex;
}

.dashboard_page_Order_Id_Wrapper_inner_div h3 {
  color: var(--white);
  font-size: 18px;
  margin-bottom: 0px;
  align-self: center;
}

button#long-button {
  border: none;
  background-color: transparent;
  padding: 4px 0px;
}

.Dashboard_page_sidebar_button {
  background-color: transparent;
  color: var(--white);
  border: none;
  font-family: "Poppins", sans-serif;
}

.Dashboard_page_sidebar_menuItem {
  padding: 10px !important;
  border-bottom: 1px solid #eaeaea !important;
  justify-content: center !important;
}

.Dashboard_page_sidebar_menuItem_last {
  justify-content: center !important;
}

.Dashboard_page_top_div_inner_div {
  margin-left: 20px;
  margin-right: 35px;
}

.Dashboard-Page-bottom-div-Main-wrapper-content-p {
  color: var(--white) !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: -6px;
}

.Dashboard-Page-bottom-div-Main-wrapper-content-span {
  color: var(--lightgrey);
}

.Dashboard-Page-bottom-div-Main-wrapper.d-flex-center button {
  background-color: var(--drakgrey);
  border: 2px solid #a2a2a2;
  border-radius: 5px;
  padding: 4px 22px;
  font-size: 12px;
  color: var(--white);
  font-weight: 200;
}

.robot_logoutbutton {
  border: none !important;
  background-color: transparent !important;
}

.button_two {
  margin: 0 !important;
  border: 2px solid var(--blue) !important;
  margin-right: 20px !important;
}

.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 20px;
  padding-left: 14px;
  color: var(--white);
  text-align: center;
}

.Modal-button-Main-btn {
  border-radius: 8px !important;
  border: 1px solid rgb(205, 4, 2);
  color: rgb(205, 4, 2);
  padding: 6px 41px !important;
  font-weight: 600 !important;
  margin: 10px !important;
  margin-top: 0px !important;
  background: transparent;
}

.yes-red-active {
  background: linear-gradient(to right, var(--blue), var(--blue)) !important;
  color: var(--white) !important;
}

.Modal-button-Main-wrapper {
  margin-top: 22px;
  text-align: center;
}

.dashboard-left-side-div-one-wrapper .Dashboard-page-teleop-button {
  border: 2px solid #e43329;
  background: #bdc4e0;
  border-radius: 7px;
  display: block;
  margin: 0 auto 10px;
  padding: 7px 20px;
  max-width: 150px;
  font-weight: bold;
  text-align: center;
}

.dashboard-left-side-div-one-wrapper .Dashboard-page-teleop-button.Teleop_completed_btn {
  border: 2px solid #e43329 !important;
  background: var(--white) !important;
  border-radius: 7px;
  display: block;
  margin: 0 auto 10px;
  padding: 7px 20px;
  max-width: 200px;
  font-weight: bold;
  font-size: 16px !important;
  text-align: center;
}

.Dashboard-page-teleop-button-disabled {
  background-color: #78797a !important;
}

.Dashboard-Page-Power-button {
  background-color: var(--drakgrey);
  border-radius: 30px;
  width: 35px !important;
  height: 35px !important;
  padding: 5px;
}

.col-md-9.dashboard-right-side-div-wrapper-Main {
  height: 90vh;
}

.Stop-button-dashhboard-page {
  background-color: rgb(228 51 41) !important;
  color: var(--white) !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border: none !important;
  padding: 6px 33px !important;
}

.play-icon {
  cursor: pointer;
}

.Dashboard-page-teleop-button {
  /* border:2px solid rgb(220,53,69) !important;  */
}

.Error-state-teleop-ack-button {
  background-color: rgb(220, 53, 69) !important;
  color: var(--white) !important;
  font-size: 10px;
  position: absolute;
  right: 0rem;
  top: -10px;
  border: none !important;
}

.dashboard_page_Map_heading_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
}

.Dashboard_page_Map_wrapper_Main_whole {
  padding: 2rem;
  padding-top: 1rem;
  padding-right: 0px;
  padding-left: 0px;
}

/* .leaflet-marker-icon {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
} */

@keyframes example-1 {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #3246B2;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #3246B2;
  }

  100% {
    border: none;
    transition: 1s ease-in-out
  }
}

.color-class-1 {
  background-color: #3246B2;
  color: #3246B2;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

@keyframes example-2 {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #53B6CC;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #53B6CC;
  }

  100% {
    border: none;
    transition: 1s ease-in-out
  }
}

.color-class-2 {
  background-color: #53B6CC;
  color: #53B6CC;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

@keyframes example-3 {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #E68D40;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #E68D40;
  }

  100% {
    border: none;
    transition: 1s ease-in-out
  }
}

.color-class-3 {
  background-color: #E68D40;
  color: #E68D40;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

@keyframes example-4 {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #74CB6B;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #74CB6B;
  }

  100% {
    border: none;
    transition: 1s ease-in-out
  }
}

.color-class-4 {
  background-color: #74CB6B;
  color: #74CB6B;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

@keyframes example-5 {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #D04622;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #D04622;
  }

  100% {
    border: none;
    transition: 1s ease-in-out
  }
}

.color-class-5 {
  background-color: #D04622;
  color: #D04622;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

@keyframes example-6 {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #292F40;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #292F40;
  }

  100% {
    border: none;
    transition: 1s ease-in-out
  }
}

.color-class-6 {
  background-color: #292F40;
  color: #292F40;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

@keyframes example-7 {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #F9ED4F;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 5px #F9ED4F;
  }

  100% {
    border: none;
    transition: 1s ease-in-out
  }
}

.color-class-7 {
  background-color: #F9ED4F;
  color: #F9ED4F;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
}

.col-md-9.dashboard-right-side-div-wrapper-Main h2 {
  font-size: 22px;
  color: var(--white);
  padding: 50px;
  padding-bottom: 0px;
  font-size: 17px;
}

.RobotActions-content-wrapper-div p {
  color: var(--white);
}

.RobotActions-content-wrapper-div {
  text-align: center;
}

.RobotActions_content_wrapper_div_orange {
  border: 2px solid #FAA659;
}

.RobotActions_content_wrapper_div_orange p span {
  color: #FAA659;
}

.No-robots-available {
  font-size: 13px;
  color: var(--white);
}

.RobotActions-content-wrapper-div {
  color: var(--white) !important;
  padding: 19px;
}

.RobotActions-content-wrapper-div h3 {
  font-size: 15px;
  margin-bottom: 10px;
  text-align: left;
}

.RobotActions-content-wrapper-div p {
  font-size: 13px;
  margin-bottom: 0px;
  text-align: left;
}

.RobotActions-button-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.RobotActions-button-wrapper button {
  border: none;
  color: var(--white) !important;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 15px;
  margin: 5px;
}

.RobotActions-Start-btn {
  background-color: rgb(40, 167, 69);
}

.RobotActions-cancel-btn {
  background-color: rgb(220, 53, 69);
}

/* ===========================End================================================================================================================================================================================================================================================================================================================================== */
/* ======================================Video Page========================================================================================================================================================================================================================================================== */
.Video_page_wrapper {
  display: grid;
  grid-template-columns: 48% 49%;
  grid-gap: 20px !important;
}

.Dashboard_page_content {
  padding: 20px 0px;
}

.side_bar_li:last-of-type {
  border-bottom: none !important;
}

.Video_page_wrapper_div {
  /* margin:10px; */
  width: 100%;
}

.Video_page_wrapper_p {
  color: var(--white);
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}

.Dashboard_page_viewstream_button img {
  margin-right: 10px;
}

.Video_stream_iframe_two {
  width: 100%;
  height: 300px;
  border-radius: 8px;
}

.side_bar_active {
  background-color: #183140;
}

.side_bar_ul {
  padding-left: 0px;
}

.side_bar_li {
  padding: 7px 10px !important;
  /* margin: 5px !important; */
  font-weight: 400;
  font-size: 13px !important;
}

.SideBar_h6 {
  color: #00B7D4;
}

.side_bar_fleetdiv_wrapper {
  margin-top: 15px;
}

.restart_video_button {
  border-radius: 8px !important;
  border: none;
  color: white;
  padding: 4px 20px !important;
  font-weight: 600 !important;
  margin: 10px;
  margin-top: 0px !important;
  background: var(--blue);
  font-size: 15px;
}

/* ======================================End========================================================================================================================================================================================================================================================== */
/* ===========================Add fleet Page================================================================================================================================================================================================================================================================================================================================== */

.AddFleet_form_field_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1rem;
  margin-top: 0rem;
}

.AddFleet_form_field_wrapper_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 0rem;
}

.AddFleet_form_field_wrapper input {
  color: var(--white);
  font-family: 'Poppins', sans-serif;
  ;
}

.AddFleet_form_field_wrapper input::file-selector-button {
  display: none;
}

.AddFleet_form_field_wrapper input::placeholder {
  color: var(--grey);
}

.AddFleet_form_field_wrapper>div>div::before {
  content: '';
  border-bottom: 0.5px solid var(--grey) !important;
}

.AddFleet_page_content form {
  width: 50%;
}

.AddFleet_form_field_wrapper>div>div {
  width: 100%;
}

.AddFleet_page_content {
  /* padding-left: 52px; */
  margin-top: 20px !important;
}

.AddFleet_page_save_button_wrapper {
  text-align: right !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.AddFleet_page_save_button_wrapper button {
  background: var(--grey);
  border: 1px solid var(--grey);
  border-radius: 50px;
  padding: 3px 33px;
  font-size: 15px;
  margin: 10px;
}

.AddOrder_text_response_wrapper p {
  background-color: var(--drakgrey);
  padding: 10px 30px;
  color: var(--grey);
  margin: 19px;
  border-radius: 24px;
  font-size: 13px;
}

.cursor_pointer {
  cursor: pointer;
}

p.Modal-wrapper-p {
  color: var(--black) !important;
  font-weight: 600;
  margin-bottom: 20px;
}

/* ===========================End==================================================================================================================================================================================================================================================================================================================================  */
/* =============================Add Map============================================================================================================================================================================================================================================================================================================================================= */
.AddMap_Modal_content_wrapper h3 {
  font-weight: 700;
  color: var(--white);
  font-size: 27px;
  margin-bottom: 26px;
}

.AddFleet_form_field_wrapper_inner_div {
  width: 46%;
  position: relative;
}

label.btn {
  position: absolute;
  right: 0;
  bottom: 9px;
}

.Add_map_cancel_save_button {
  text-align: flex-end;
}

.Add_map_cancel_save_button button {
  cursor: pointer;
}

.AddMap_text_error {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 16px;
}

.AddMap_text_error_two {
  color: #d32f2f;
  font-weight: 400;
  font-size: 0.75rem;
}

.AddFleet_form_field_wrapper label {
  color: white !important;
  font-size: 13px;
  margin-left: 15px;
}

.AddFleet_form_field_wrapper_two label {
  color: rgb(108 111 120);
  margin-left: 0px;
  font-weight: 500;
  font-family: 'Poppins';
  letter-spacing: 0px;
}

.Add_map_cancel_save_button button {
  background: var(--grey);
  border: 1px solid var(--grey);
  border-radius: 50px;
  padding: 3px 33px;
  font-size: 15px;
  margin: 10px;
}

/* =================================End=========================================================================================================================================================================================================================================================================================================================================================== */
/* ================Edit Map============================================================================================================================================================================================================================================================================================================================================================ */
.EditMap_button {
  background: var(--grey);
  border: none;
  color: #000;
  margin-right: 10px;
  margin-left: 10px;
  padding: 5px 21px;
  border-radius: 5px;
  max-width: 164px;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.Delete_map_btn {
  background: transparent;
  border: 2px solid #E96C67;
  color: #E96C67;
}

table.Fleet_page_table_Main_whole {
  /* margin-left:37px; */
  margin-top: 20px;
}

table.Fleet_page_table_Main_whole td:nth-child(2) {
  white-space: nowrap;
}

/* =================================End=========================================================================================================================================================================================================================================================================================================================================================== */
/* ==============OrderList Page================================================================================================================================================================================================================================================================================================================================================== */
.Dashboard_page_right_side_fleetpage_orderlist {
  color: var(--white);
  width: 100%;
  margin-left: 0px;
  overflow-x: scroll;

}

button.OrderLIst_page_confirm_button {
  font-size: 12px;
  padding: 7px 10px;
  background-color: var(--medblue);
  color: var(--white);
  border: none;
}

.Orderlist_page_table_wrapper {
  /* width:94%;
  overflow-x: scroll; */
  width: 100%;
  overflow-x: scroll;
  position: absolute;
}

::-webkit-scrollbar {
  background-color: var(--drakgrey);
  color: var(--white) !important;
  width: 10px;
  height: 10px;
  border-radius: 13px;
}

::-webkit-scrollbar-track {
  color: var(--white) !important;
}

::-webkit-scrollbar-thumb {
  background-color: #7d7d7d !important;

}

.Orderlist_page_table_wrapper::-webkit-scrollbar {
  background-color: var(--drakgrey);
  color: var(--white) !important;
  width: 10px;
  height: 10px;
  border-radius: 13px;
}

.Orderlist_page_table_wrapper::-webkit-scrollbar-track {
  color: var(--white) !important;
}

.Orderlist_page_table_wrapper::-webkit-scrollbar-thumb {
  background-color: #7d7d7d !important;

}

.popup_display_none {
  visibility: hidden !important;
}

/* ==================End================================================================================================================================================================================================================================================================================================================================================================ */
/* ===========================IndividualUserPage==================================================================================================================================================================================================================================================================================================================================  */
.Individual_User_page_row_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

.Individual_userCheck_box_input svg {
  color: var(--grey);
  background-color: var(--medblue);
  font-family: 'Poppins', sans-serif !important;
}

.AddFleet_page_save_button_wrapper.Individual_user_page {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
}

.Individual_UserPage_Input_checkbox_wrapper span {
  font-size: 13px !important;
}

/* ===========================End==================================================================================================================================================================================================================================================================================================================================  */

/* ===========================Delete Modal==================================================================================================================================================================================================================================================================================================================================  */
.Delete_modal_close_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 10px 20px 0 0 !important;
}

.Modal_content_button_wrapper {
  text-align: center;
}

.Text_Field_Input_class label {
  color: var(--white);
}

/* ===========================End==================================================================================================================================================================================================================================================================================================================================  */
/* ===============================Robot_Page================================================================================================================================================================================================================================================================================================================================== */
td.Robot_page_robotId_td {
  width: 200px !important;
  font-size: 15px;
  white-space: nowrap;
}

.Robot_page_robotId_td span {
  padding: 0px;
  line-height: 0px;
  font-size: 10px;
}

.AddFleet_form_field_wrapper_two fieldset {
  border: none !important;
}

.AddFleet_form_field_wrapper_two svg {
  color: rgb(108 111 120);
}

.RobotPage_filter_div_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 3%;
}

.RobotPage_filter_inner_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}

.RobotPage_filter_inner_div label, .RobotPage_filter_inner_div svg {
  color: white !important;
}

.col-md-10 {
  padding-bottom: 20px;
}

/* ===================================End============================================================================================================================================================================================================================================================================================================================================ */
/* ==========================Responsive================================================================================================================================================================================================================================================================================================================= */
@media only screen and (max-width: 1192px) {

  /* .col-md-10 {
    height: calc(100% - 100px);
    overflow-y: auto;
  } */

  .col-md-10>div {
    height: calc(100vh - 100px);
    /* overflow-x: auto; */
  }

  .map_col_10>div {
    overflow: hidden !important;
  }

  .Fleet_page_Whole_content_wrapper_main {
    height: calc(100% - 90px);
    overflow: auto;
  }

  .Fleet_page_Whole_content_wrapper_main {
    height: calc(100% - 90px);
    overflow: auto;
  }

  .Dashboard_side_bar_wrapper_main_long {
    width: 20%;
    top: 5%;
    display: none !important;

  }

  .col-md-10 {
    width: 100% !important;
  }

  .margin_left {
    margin-left: 0%;
  }

  .SideBar_active {
    font-size: 14px;
  }

  .Dashboard_bottom_bar_whole_wrapper {
    display: block !important;
  }

  .FleetPage_plusIcon {
    position: fixed !important;
    bottom: 95px !important;
    right: 20px !important;
    color: #000 !important;
    background-color: var(--grey);
    border-radius: 100%;
    padding: 13px;
  }

  .Dashboard_page_right_side_fleetpage {
    /* margin-top: 125px; */
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {

  .Dashboard_page_right_side_fleetpage {
    overflow-x: scroll;
  }

  .Hamburger_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: flex-end;
    padding: 0px 15px;
  }

  .Hamburger_icon_wrapper svg {
    font-size: 40px;
    color: var(--blue);
  }

  .Dashboard_page_rightSide_content_heading>div:nth-child(1) {
    /* margin:15px; */
  }

  .Dashboard_page_rightSide_content_heading {
    /* display: block; */
  }

  .FleetPage_top_bar_left_side div {
    margin: 0px 10px;
    font-size: 15px;
  }

  span.state_border {
    white-space: nowrap !important;
    width: 100px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block;
    position: relative;
  }

  span.state_border::after {
    content: '' !important;
    display: block !important;
    width: 100% !important;
    position: absolute !important;
    left: 0 !important;
    bottom: -2px !important;
    height: 4px !important;
  }

  .Dashboard_page_right_side {
    width: 100%;
  }


  .Fleet_page_Mappage_Maptype_table_wrapper {
    overflow-x: scroll;
  }

  .Individual_fleet_page_locations_wrapper {
    display: block;
  }

  .Individual_fleet_page_pickup_location_wrapper, .Individual_fleet_page_dropoff_location_wrapper {
    margin: 15px;
  }

  .Individual_fleet_page_Home_location_content_wrapper {
    overflow-x: scroll;
  }

  table.Individual_fleet_page_location_content_table {
    white-space: nowrap;
  }

  .Orderlist_page_table_wrapper {
    position: relative;
  }

  img.Dashboard_SideBar_icon {
    width: 30px !important;
    height: 30px !important;
  }

  .Dashboard_side_bar_P {
    font-size: 14px;
  }

  /* .margin_left {
  margin-left: 20%;
} */
  button.menu-bar-btn {
    display: none;
  }

  .Dashboard_page_side_bar li {
    padding-left: 25px;
  }

  .Header_logo_wrapper img {
    width: 14%;
  }

  .Header_Right_side_content div h5, .Header_Right_side_content div p {
    font-size: 9px;
  }

  .Dashboard_side_bar_wrapper_main_long {
    width: 20%;
    top: 8%;
    display: none !important;
  }

  .Dashboard_page_rightSide_content_heading {
    /* display: block; */
  }

  .Dashboard_page_right_side {

    padding-right: 50px;
    padding-left: 24px;
  }

  .Map_view_select_box {
    justify-content: flex-end !important;
  }

  .FleetPage_table_action_td img {
    margin-bottom: 13px;
  }

  .FleetView_page_haeding_tag {
    font-size: 19px;
  }

  .FleetView_page_Heading_fleetName {
    font-size: 13px;
  }

  .FleetView_page_OrderList_content button {
    color: var(--black) !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 13px !important;
    background-color: var(--lightgrey) !important;
    padding: 6px 39px !important;
    border-radius: 50px !important;
    border: none;
    font-weight: 700;
  }

  .FleetView_page_Play_Pause_wrapper p {
    font-size: 9.7px;
  }

  .AddFleet_page_content form {
    width: 80%;
  }

  /* .Login-page-form-logo-image {
    width: 16%;
  } */

  .Login-page-form {
    width: 70%;
  }

  .RobotPage_filter_inner_div {
    width: 50%;
  }

  .collapse_side_bar_class {
    max-width: 15% !important;
  }
}

@media only screen and (max-width: 992px) {
  .Dashboard_page_right_side_fleetpage {
    height: calc(100% - 90px);
    overflow: auto;
  }

  .Fleetrobotpage_popup_wrapper {
    display: none;
  }

  #menu_dropdown_select {
    display: block !important;
  }

  .FleetPage_top_bar_left_side {
    display: none;
  }

  .Fleetrobotpage_popup_wrapper_two {
    display: block !important;
  }

  .Orders_count_div {
    display: block !important;
  }

  .FleetPage_Top_bar {
    text-align: center !important;
  }

  .Orders_count_div div {
    margin: 15px;
    text-align: center;
  }

  .Assign-btn {
    position: relative !important;
    top: 0% !important;
    right: 0% !important;
  }

  .Fleet_page_Mappage_Maptype_table_wrapper {
    overflow-x: scroll;
  }

  /* .Battery_swap_span{
    position: absolute !important;
    right:0;
    top:0;
  } */
  .collapse_side_bar_class {
    max-width: 21% !important;
  }

  .Header_Main_whole_wrapper {
    padding: 15px 31px;
  }

  .Header_Right_side_content div {
    padding-right: 0px;
  }

  .Dashboard_side_bar_wrapper_main_long {

    display: none !important;
  }

  /* .Dashboard_bottom_bar_whole_wrapper {
    display: block !important;
  } */

  .Individual_fleet_page_locations_wrapper {
    display: block;
  }

  .Individual_fleet_page_pickup_location_wrapper, .Individual_fleet_page_dropoff_location_wrapper {
    margin: 0px;
  }

  .Individual_fleet_page_Home_location_content_wrapper {
    overflow-x: scroll;
  }

  table.Individual_fleet_page_location_content_table {
    white-space: nowrap;
  }

  .Orderlist_page_table_wrapper {
    position: relative;
  }

  .AddMap_modal_wrapper {
    width: 650px !important;
  }

  #AddMap_choose_image input {
    width: 100%;
  }

  .RobotPage_filter_inner_div {
    width: 55%;
  }

  .col-md-4 {
    width: -webkit-fill-available;
  }

  .fleet_div {
    margin: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .Header_Main_whole_wrapper {
    padding: 12px 22px;
  }

  .Login-page-form-logo-image {
    height: 40px;
  }

  .Fleetrobotpage_popup_wrapper {
    display: none;
  }

  .Fleetrobotpage_popup_wrapper_two {
    display: block !important;
  }

  .Fleetrobotpage_popup_wrapper {
    display: none !important;
  }

  .Header_Right_side_content div {
    padding-right: 0px;
  }

  .FleetPage_top_bar_left_side div {
    margin: 0px 10px;
    font-size: 10px;
  }

  .Header_logo_wrapper img {
    width: 34%;
  }

  span.state_border {
    white-space: nowrap !important;
    width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: inline-block;
    position: relative;
  }

  .Dashboard_side_bar_wrapper_main_long {
    display: none !important;
  }

  .Dashboard_SideBar_icon_one {
    width: 30px;
  }

  .Dashboard_side_bar_wrapper_main_short {
    display: none;
  }

  .Dashboard-Page-bottom-div-Main-wrapper {
    display: block !important;
  }

  .Dashboard_page_bottom_div_inner_one_div {
    width: 100% !important;
  }

  /* 
  .Dashboard_bottom_bar_whole_wrapper {
    display: block !important;
  } */

  .Dashboard_side_bar_P {
    /* display:none; */
  }

  .AddFleet_page_content form {
    width: 80%;
  }

  .RobotPage_filter_inner_div {
    width: 70%;
  }

  .AddFleet_page_content {
    padding-left: 17px;
    margin-top: 1rem !important;
  }

  div#AddMap_Modal_textfield_wrapper {
    display: flex;
  }

  #AddMap_modal_upload_image {
    display: none;
  }

  #AddMap_choose_image input {
    width: 103%;
  }

  .FleetPage_top_bar_wrapper {
    background-color: var(--drakgrey);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 44px;
    overflow-x: scroll;
  }

  .Orderlist_page_table_wrapper {
    position: relative;
  }

  .AddMap_modal_wrapper {
    width: 350px !important;
  }

  .SelectPage-robotList-wrapper {
    margin: 5px;
  }

  .Individual_fleet_page_location_content_table {
    white-space: nowrap;
  }

  .Individual_fleet_page_dropoff_location_wrapper {
    margin-top: 39px;
  }

  .Individual_fleet_page_Home_location_content_wrapper {
    overflow-x: scroll;
  }

  .Individual_fleet_page_location_header_wrapper {
    white-space: nowrap;
  }

  .Fleet_page_Mappage_Maptype_table_wrapper {
    overflow-x: scroll;
  }

  .Individual_fleet_page_locations_wrapper {
    display: block;
  }

  .Dasboard_page_individual_fleet_box_inner_div p:last-child {
    font-size: 8px;
  }

  .Dashboard_page_rightSide_content_heading span {
    font-size: 13px;

  }

  .Dasboard_page_individual_robot_box_wrapper {
    /* width:350px !important; */
    margin: 0px !important;
    margin-top: 20px !important;
    padding: 10px;
  }

  .FleetView_page_haeding_tag {
    font-size: 14px;
    white-space: nowrap;
  }

  .FleetView_page_Heading_fleetName {
    font-size: 10px;
  }

  .FleetView_page_OrderList_pause_wrapper {
    justify-content: flex-end;
    padding-right: 20px;
  }

  .FleetView_page_OrderList_content button {
    color: var(--black) !important;
    text-transform: capitalize !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 10px !important;
    background-color: var(--lightgrey) !important;
    padding: 1px 23px !important;
    border-radius: 50px !important;
    border: none;
    font-weight: 700;
  }

  .FleetView-Page-Top-Content-wrapper {
    justify-content: space-between;
    padding: 0px 21px;
    padding-right: 50px;
    display: flex;
    width: 100%;
  }

  .Dasboard_page_individual_fleet_box_inner_div p {
    /* color: #BCC4DF;
  font-size: 10px; */
  }

  .Dasboard_page_individual_fleet_box_wrapper h4 {
    /* font-size: 15px; */
  }

  .Dasboard_page_individual_robot_h4 {
    font-size: 13px;
  }

  /* .Login-page-form-logo-image {
    width: 30%;
  } */

  .Login-page-form {
    width: 80%;
  }

  .Login_page-wholeMain-wrapper:before {
    background-size: 100px;
  }

  .Login_page-wholeMain-wrapper:after {
    background-size: 100px;
  }

  .Header_Right_side_content div {
    padding-right: 0px !important;
  }

  .Login-page-form {
    padding: 20px;
  }
}

/* ==========================End================================================================================================================================================================================================================================================================================================================= */

.Toastify__toast-container--top-right {
  z-index: 9999999999999999999999999 !important;
  text-transform: capitalize !important;
  font-weight: 600;
}


@keyframes example {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 3px #fff;
    border-radius: 100%;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 3px #fff;
    border-radius: 100%;
  }

  100% {
    border: none;
    transition: 1s ease-in-out;
    border-radius: 100%;
  }
}

.gm-style div:nth-child(2) div:nth-child(2) div div:nth-child(3) div {
  /* position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%; */
}


.new {
  position: relative;
}

.CPU-p-tag-fleetpage {
  position: absolute;
  bottom: 4px;
  left: 5px;
  color: #FF6767;
  font-size: 13px;
  font-weight: 700;
}

.dashboard-left-side-div-one-wrapper_ERROR {
  border: 5px solid #fb6262;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Moving {
  border: 5px solid #7859bc;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.No_video_available_div {
  background-color: var(--drakgrey);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.No_video_available_div p {
  color: var(--white);
  font-size: 17px;
}

.robotRunningState_teleopmoving {
  background-color: #7859bc;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid #7859bc;
}

.teleopMoving_bordertop {
  border-top: 8px solid #7859bc;
}

.teleopMoving_bordertop_p {
  color: var(--white);
}

.teleopMoving_bordertop_p span.state_border::after {
  background: #7859bc;
}

.dashboard-left-side-div-one-wrapper.state_Auto_run_moving {
  border: 5px solid #40B4D0;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_autorunmoving {
  background-color: #40B4D0;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid #40B4D0;
}

.AutoRun_moving {
  border-top: 8px solid #40B4D0
}

.AutoRun_moving_p span.state_border::after {
  background: #40B4D0;
}

.AutoRun_moving_p {
  color: var(--white);
}

.dashboard-left-side-div-one-wrapper.state_Auto_Obstacle-Teleop_Obstacle {
  border: 5px solid #e6883c;
  padding: 25px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_state_Auto_Obstacle-Teleop_Obstacle {
  background-color: #e6883c;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid #e6883c;
}

.AutoObstacle_teleop_obstacle_bordertop {
  border-top: 8px solid #e6883c;
}

.AutoObstacle_teleop_obstacle_p {
  color: var(--white);
}

.AutoObstacle_teleop_obstacle_p span.state_border::after {
  background: #e6883c;
}

.dashboard-left-side-div-one-wrapper.state_Auto_system_fault_error {
  border: 5px solid rgb(228 51 41);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_autoSystemFault {
  background-color: rgb(228 51 41);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid rgb(228 51 41);
}

.border_red_battery {
  border: 3px solid rgb(228 51 41);
}

.Battery_swap_text {
  text-align: center;
  background-color: rgb(255, 103, 103);
  color: white;
  margin-top: 9px;
  border-radius: 100%;
  padding: 5px;
  margin-right: 10px;
}

.Battery_swap_span {
  position: relative;
  padding: 6px;
  background-color: #fb6262;
  border-radius: 20px;
}

/* .Battery_swap_text p{
  font-size:15px;
} */
.AutoSystem_fault_error_bordertop {
  border-top: 8px solid rgb(228 51 41);
}

.AutoSystem_fault_error_p {
  color: var(--white) !important;
}

.Fleetrobotpage_popup_wrapper {
  display: flex;
  position: absolute;
  bottom: 46px;
  left: 22px;
  width: 94%;
}

.Fleetrobotpage_popup_wrapper_two {
  display: flex;
  position: absolute;
  bottom: 46px;
  left: 8px;
  width: 96%;
  border-radius: 8px !important;
}

.margin_top_bottom {
  margin-bottom: 10px;
}

.accordion-button:not(.collapsed), .accordion-button:focus {
  box-shadow: none;
}

.accordion-item button {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.Collapse_div_wrapper {
  border-left: 9px solid #1C1E2A;
  border-radius: 7px;
}

.Collapse_div_wrapper>div {
  float: left;
  padding: 5px 20px;
  color: white;
  cursor: pointer;
  white-space: noWrap;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.Collapse_div_wrapper>:first-of-type {
  border-right: none !important;
  width: 120px;
}

.Collapse_div_wrapper>div:nth-child(2) {
  width: 100px;
}

.Collapse_div_wrapper>div:nth-child(3) {
  width: 120px;
}

.Collapse_div_wrapper>div:nth-child(4) {
  width: 260px;
}

.Collapse_div_wrapper> :last-of-type {
  float: right !important;
  border-right: none !important
}

.Collapse_div_wrapper>div:nth-child(5) {
  border-right: none !important;
  width: 230px;
}

.Collapse_div_displaynone {
  display: none;
}

.Collapse_div_transition {
  animation: collapse 1.5s ease-in-out;
}

.Collapse_coordinates_p {
  font-size: 13px;
}


@keyframes collapse {
  0% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.Collapse_transition_Class {
  transition: max-width 1s ease;
  overflow: hidden;
  background-color: #282F42;
  /* margin:auto; */
  border-radius: 10px 0px 0px 10px;
  /* position: absolute; */
  /* bottom:56px; */
  width: 100%;
}

.Collapse_transition_Class table {
  width: 100%;
  /* border-left: 9px solid #1C1E2A; */
  color: white;
}

.Collapse_transition_Class table td {
  /* padding:7px; */
  white-space: nowrap !important;
  /* padding-right: 17px; */
  margin: 7px;
  /* padding-left:10px; */
}

.Collapse_transition_Class table td:nth-child(1) {
  padding-left: 10px;
  width: 120px;
}

.Collapse_transition_Class table td:nth-child(2) {
  width: 120px;
}

.Collapse_transition_Class table td:nth-child(3) {
  width: 120px;

}

.Collapse_transition_Class table td:nth-child(4) {
  width: 290px;
  color: white !important;
}

.Collapse_transition_Class table tr {
  vertical-align: initial;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.Collapse_transition_Class table tr:nth-last-child() {
  border-bottom: 0px;
}

.Collapse_btn {
  background-color: #282F42;
  border-left: 1px solid var(--blue);
  border-radius: 0px 10px 10px 0px;
  color: var(--blue);
}

.Battery_percent {
  margin-top: 5px;
  font-size: 10px;
  margin: 5px;
  margin-bottom: 0px;

}

.border_right {
  border-right: 2px solid rgba(255, 255, 255, 0.5);
  margin: 5px;
}

span.state_border {
  display: inline-block;
  position: relative;
}

span.state_border::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
}

.AutoSystem_fault_error_p span.state_border::after {
  background: rgb(228 51 41);
}

.dashboard-left-side-div-one-wrapper.state_payload_state {
  border: 5px solid #f0b156;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_payloadState {
  background-color: #f0b156;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid #f0b156;
}

.payloadState_bordertop {
  border-top: 8px solid #f0b156;
}

.payloadState_bordertop_p {
  color: var(--white);
}

.payloadState_bordertop_p span.state_border::after {
  background: #f0b156;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Autorun {
  border: 5px solid rgb(113, 207, 98);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_teleop_Autorun, .robotRunningState_state_Teleop_Autorun {
  background-color: rgb(113, 207, 98);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid rgb(113, 207, 98);
}

.Teleop_autoRun_borderTop {
  border-top: 8px solid rgb(113, 207, 98);
}

.Teleop_autoRun_p {
  color: var(--white);
}

.Teleop_autoRun_p span.state_border::after {
  background: rgb(113, 207, 98);
}

.robotRunningState_default {
  background-color: var(--drakgrey);
  border: 2px solid #a2a2a2;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
}

.dashboard-left-side-div-one-wrapper {
  border: 2px solid #a2a2a2;
  position: relative;
}

.defaultBorderTop {
  border-top: 8px solid #a2a2a2;
}

.defaultBorderTop_P {
  color: var(--white);
}

.defaultBorderTop_P span.state_border::after {
  background: #a2a2a2;
}

.RobotRunning_state_Button_wrapper {
  text-align: center;
  margin: 18px;
  word-break: break-word;
}

.tools {
  text-align: end;
  position: absolute;
  z-index: 999999;
  right: 20px;
}

.mapboxgl-popup-content {
  border-radius: 8px !important;
  width: 350px;
  background: none !important;
}

.mapboxgl-popup-close-button {
  font-size: 26px !important;
  color: var(--white) !important;
  background-color: var(--darkblue) !important;
  border-radius: 100% !important;
  /* padding:10px !important; */
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px !important;
}

.Coordinates_p {
  font-weight: bold;
}

.tools button {
  background: var(--medblue) !important;
  font-weight: 600;
  margin: 5px;
  color: var(--grey);
  border: none;
  padding: 2px 12px;
  border-radius: 5px;
}

.Dashboard-content-div-one-left-side-h3 {
  color: var(--white);
  text-align: left !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 25px;
  margin-bottom: 0px;
}

.dashboard-left-side-div-one-wrapper {
  border: 2px solid #a2a2a2;
  padding: 25px 10px;
  border-radius: 7px;
  background-color: var(--black);
  position: relative;
}

.direction-toggle {
  display: flex;
  align-items: center;
  /* margin-left:30px;
  margin-right:10px; */
}

.direction_toggle_p {
  color: var(--white) !important;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0px !important;
  margin-left: -6px;
}

.Pause_btn {
  background-color: rgb(220, 53, 69) !important;
  color: var(--white) !important;
  border: none;
  border-radius: 8px;
  padding: 3px 32px;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
}

.Play_btn {
  background-color: rgb(113, 207, 98) !important;
  color: var(--white) !important;
  border: none;
  border-radius: 8px;
  padding: 3px 32px;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
}

.Assign-btn {
  background-color: var(--blue) !important;
  color: white !important;
  border: none;
  border-radius: 8px;
  /* padding: 2px 10px; */
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  right: 1%;
  top: 4%;
}

.battery-icon-css {
  width: 35px;
  position: relative;
  margin: 5px;
}

.battery-icon-css_one {
  width: 30px !important;
  border: 1px solid var(--white) !important;
  border-radius: 4px !important;
  padding: 2px !important;
  height: 14px !important;
  display: inline-block !important;
}

.battery-strong {
  display: block !important;
  height: 8px !important;
  font-weight: bold !important;
}

.Battery_p {
  font-size: 10px !important;
  text-align: center !important;
  font-weight: bold !important;
}

.signal_one {
  background: var(--white) !important;
  width: 3px !important;
  height: 3px !important;
  display: inline-block !important;
  border-radius: 3px !important;
  margin-right: 3px !important;
}

.signal_two {
  background: var(--white) !important;
  width: 3px !important;
  height: 6px !important;
  display: inline-block !important;
  border-radius: 3px !important;
  margin-right: 3px !important;
}

.signal_three {
  background: var(--white) !important;
  width: 3px !important;
  height: 9px !important;
  display: inline-block !important;
  border-radius: 3px !important;
  margin-right: 3px !important;
}

.signal_four {
  background: var(--white) !important;
  width: 3px !important;
  height: 12px !important;
  display: inline-block !important;
  border-radius: 3px !important;
  margin-right: 3px !important;
  opacity: 0.5 !important;
}

.signal_five {
  background: var(--white) !important;
  width: 3px !important;
  height: 15px !important;
  display: inline-block !important;
  border-radius: 3px !important;
  margin-right: 3px !important;
  opacity: 0.5 !important;
}

.Signal_div_wrapper {
  margin: 5px;
  margin-left: 5px
}

.battery-icon-css:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: var(--white);
  position: absolute;
  top: 6px;
  right: 2px;
}

.battery-icon-css_two {
  width: 35px;
  position: relative;
}

.battery-icon-css_two:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: var(--white);
  position: absolute;
  top: 8px;
  right: 0px;
}

.pick-drop-css {
  color: var(--white);
}

.Robot_page_robotId_td span {
  display: block;
  line-height: 16px;
  opacity: 0.7;
}

.name-log button {
  position: absolute;
  right: 10px;
  top: 12px;
  min-height: inherit;
  min-width: inherit;
}

.name-log_two button {
  position: absolute;
  right: 10px;
  top: 12px;
  min-height: inherit;
  min-width: inherit;
}

.name-log_collapse {
  display: none;
}

@media screen and (max-width:1600px) {
  .col-md-2 {
    /* padding-top: 20px; */
    flex: 0 0 auto;
    width: 20%;
  }

  .col-md-1 {
    /* padding-top: 20px; */
    flex: 0 0 auto;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 80%;
  }
}

@media screen and (max-width:1300px) {
  .col-md-2 {
    padding-top: 20px;
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 75%;
  }
}

.over-scroll {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
}

@media screen and (max-width:1024px) {
  .col-md-2 {
    display: none;
  }

  .col-md-10 {
    width: 100%;
    margin-bottom: 100px;
  }

  .Video_page_wrapper {
    display: grid;
    grid-template-columns: 98%;
    grid-gap: 20px !important;
  }

  .Dashboard_bottomBar_ul_wrapper li img {
    /* display: none !important; */
    margin: 0 0 5px !important;
    width: 30px !important;
    height: 30px !important;
  }

  .Dashboard_bottomBar_ul_wrapper li img.logo-btm {
    display: block !important;
    width: auto !important;
    height: 40px !important;
  }

  .Dashboard_bottomBar_ul_wrapper li button svg {
    width: 35px !important;
    height: 35px !important;
    margin-bottom: 5px;
    /* background: #BCC4DF; */
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.Chart_wrapper {
  height: 250px;
  padding: 8px;
  text-align: left;
  font-size: 16px;
  width: 100%;
  font-weight: bold;
  padding-top: 0px;
}

.Chart_wrapper p {
  margin-bottom: 10px !important;
  color: var(--white);
}

.Chart_wrapper span {
  color: var(--blue);
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

#heat-map-container-id canvas {
  border-radius: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.edit__location .MuiAutocomplete-inputRoot {
  color: white !important;
  font-size: 13px !important;
  border-bottom: 1px solid rgb(151, 159, 184) !important;
}

.edit__location .MuiSvgIcon-root {
  fill: rgb(151, 159, 184) !important;
}

.edit__location .MuiAutocomplete-inputRoot::before {
  border-bottom: none !important;
}